import { BenefitBulletType, UserData } from '../types/common';

export type BIRole = 'member' | 'owner' | 'visitor';

export function userRoleToBIRole(role: UserData['role']): BIRole {
  switch (role) {
    default:
    case 'Member':
      return 'member';
    case 'Admin':
      return 'owner';
    case 'Visitor':
      return 'visitor';
  }
}

export function toBIPaymentType(recurring?: boolean) {
  return recurring ? 'recurring' : 'one-time';
}

export const SETTINGS_TABS = {
  display: 'display',
  layout: 'layout',
};

export const DISPLAY_VALUES = {
  enabled: 'enabled',
  disabled: 'disabled',
};

export function benefitBulletTypeName(value: BenefitBulletType) {
  return benefitBulletTypeMap[value];
}

const benefitBulletTypeMap = {
  [BenefitBulletType.NONE]: 'none',
  [BenefitBulletType.CIRCLE_TICK]: 'circle_tick',
  [BenefitBulletType.HOLLOW_CIRLCE_TICK]: 'hollow_circle_tick',
  [BenefitBulletType.TICK]: 'tick',
  [BenefitBulletType.ARROW]: 'arrow',
};
