import { MembersGroupsWeb } from '@wix/ambassador-members-groups-web/http';
import { isTruthy } from '../utils/isTruthy';
import { ApiHeaders } from './headers';

export type MembersGroupsApi = ReturnType<typeof MembersGroupsWeb>;
export type GroupsService = ReturnType<MembersGroupsApi['GroupsService']>;

export class GroupsApi {
  constructor(protected headers: ApiHeaders, protected groupsService: GroupsService) {}

  public async getMissingPlanIds(pageId: string) {
    const { groups = [] } = await this.groupsService(this.headers).list({
      type: 'plan',
      resourceId: pageId,
    });
    return groups.map((group) => group.id).filter(isTruthy);
  }
}

export function createGroupsApi(headers: ApiHeaders, baseUrl: string = '') {
  return new GroupsApi(headers, MembersGroupsWeb(baseUrl + '/_api/members-groups-web').GroupsService());
}
