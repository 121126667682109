import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';

import { plansFixture } from '../../../fixtures';
import { OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import { PricingPlansBi } from '../../../services/bi';
import { ListProps, CommonProps, IntegrationData, PopupEnum, MessageCode } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { PurchaseLimitExceededError } from '../../../utils/errors';
import { getIds, isFree } from '../../../utils/plan';
import { Router } from './Router';
import { SettingsReader } from '../DefaultSettingsAdapter';
import { countFullPages, hasMorePages } from '../../../utils/pagination';
import { PLANS_QUERY_PAGE_SIZE } from '../../../constants';

export class ListController {
  constructor(
    protected setProps: (props: Partial<CommonProps & ListProps>) => void,
    protected wixCodeApi: IWixAPI,
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected premiumApi: PremiumApi,
    protected bi: PricingPlansBi,
    protected analytics: Analytics,
    protected settings: SettingsReader,
  ) {}

  async initialize(integrationData: IntegrationData) {
    return this.update(integrationData);
  }

  async update(integrationData: IntegrationData) {
    this.flowAPI.fedopsLogger.interactionStarted(PackagePickerInteractions.ListPageLoaded);

    const { plans, hasMorePlans = false } = integrationData.planIds
      ? { plans: await this.plansApi.loadPaidPlans({ planIds: integrationData.planIds }) }
      : await this.fetchPlans();

    if (this.flowAPI.environment.isViewer) {
      this.bi.plansPageView(integrationData.biOptions, 'page');
    }
    this.analytics.addProductImpression(plans);

    this.setProps({
      plans,
      hasMorePlans,
      popup: null,
      hidePopup: this.hidePopup,
      selectPlan: (plan) => this.selectPlan(plan, integrationData),
      loadMore: () => this.fetchMorePlans(plans),
    });
  }

  fetchPlans = async () => {
    let plans: PublicPlan[] = [];
    let hasMorePlans = false;

    if (this.settings.get('usePagination')) {
      plans = await this.plansApi.query({
        exclude: this.settings.asArray('hiddenPlans'),
      });
      hasMorePlans = hasMorePages(plans, PLANS_QUERY_PAGE_SIZE);
    } else {
      plans = await this.plansApi.loadPaidPlans();
    }
    if (!plans.length && this.wixCodeApi.window.viewMode === 'Editor') {
      plans = plansFixture;
    }

    return { plans, hasMorePlans };
  };

  fetchMorePlans = async (currentPlans: PublicPlan[]) => {
    const nextPage = await this.plansApi.query({
      page: countFullPages(currentPlans, PLANS_QUERY_PAGE_SIZE),
      exclude: this.settings.asArray('hiddenPlans'),
    });
    this.setProps({
      plans: [...currentPlans, ...nextPage],
      hasMorePlans: hasMorePages(nextPage, PLANS_QUERY_PAGE_SIZE),
    });
  };

  protected selectPlan = async (plan: PublicPlan, integrationData: IntegrationData) => {
    this.flowAPI.fedopsLogger.interactionStarted(PackagePickerInteractions.PlanSelected);
    const planId = plan.id;

    if (!planId) {
      this.flowAPI.reportError('Plan has no id.');
      return;
    }

    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({ popup: PopupEnum.checkoutPreview });
      this.flowAPI.fedopsLogger.interactionEnded(PackagePickerInteractions.PlanSelected);
      return;
    }

    this.bi.planPurchaseClick(plan.id!, 'page');
    this.analytics.clickProduct(plan);

    const { currentUser } = this.wixCodeApi.user;
    if (currentUser.loggedIn) {
      try {
        if (!isFree(plan) && (await this.premiumApi.shouldUpgrade())) {
          this.setProps({ shouldUpgrade: true });
          if (currentUser.role === 'Admin') {
            this.setProps({
              showUpgradeModal: true,
              continueToDemoCheckout: () => {
                this.setProps({ showUpgradeModal: false });
                this.router.gotoCheckout(plan, integrationData);
              },
            });
          } else {
            this.router.gotoCheckout(plan, integrationData);
          }
        } else {
          const order = await this.ordersApi.createOrder(plan, currentUser.id);
          if (isFree(plan) && order) {
            this.router.gotoStatus(plan, order, integrationData);
          } else {
            this.router.gotoCheckout(plan, integrationData, order);
          }
        }
      } catch (e) {
        if (e instanceof PurchaseLimitExceededError) {
          this.setProps({ message: MessageCode.PURCHASE_LIMIT_ERROR });
        } else {
          this.flowAPI.reportError(e);
          this.setProps({ message: MessageCode.UNKNOWN_ERROR });
        }
      }
    } else {
      this.router.gotoCheckout(plan, integrationData);
    }

    this.flowAPI.fedopsLogger.interactionEnded(PackagePickerInteractions.PlanSelected);
  };

  protected hidePopup = () => this.setProps({ popup: null });
}
