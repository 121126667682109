import { V1OrdersOrder } from '@wix/ambassador-membership-api/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { IUser, IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';

import { BenefitsApi, OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { CheckoutData, CheckoutProps, CommonProps, IntegrationData, MessageCode } from '../../../types/common';
import { getUserData } from '../../../utils/user';
import { errorToMessage, PurchaseLimitExceededError } from '../../../utils/errors';
import { Router } from './Router';
import { PaymentResultReader } from '../../../utils/PaymentResultReader';
import { BOOKINGS_APP_DEF_ID } from '../../../constants';
import { isFree } from '../../../utils/plan';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { Analytics } from '../../../services/analytics';
import { PricingPlansBi } from '../../../services/bi';

export class CheckoutController {
  constructor(
    protected setProps: (props: Partial<CommonProps & CheckoutProps>) => void,
    protected wixCodeApi: IWixAPI,
    protected router: Router,
    protected flowAPI: ControllerFlowAPI,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected benefitsApi: BenefitsApi,
    protected premiumApi: PremiumApi,
    protected analytics: Analytics,
    protected bi: PricingPlansBi,
  ) {}

  async initialize(checkoutData: CheckoutData) {
    this.flowAPI.fedopsLogger.interactionStarted(PackagePickerInteractions.CheckoutPageInitialized);
    const {
      currentUser: { loggedIn, id },
    } = this.wixCodeApi.user;
    const { planId, memberId, orderId, integrationData } = checkoutData;
    const plans = await this.plansApi.loadPaidPlans({ planIds: [planId] });
    this.flowAPI.fedopsLogger.interactionEnded(PackagePickerInteractions.CheckoutPageInitialized);

    if (plans.length < 1) {
      this.router.gotoList(integrationData, MessageCode.PLAN_NOT_FOUND);
    } else {
      const [selectedPlan] = plans;
      const isSameMember = loggedIn && memberId === id;
      try {
        await this.update(
          selectedPlan,
          integrationData,
          isSameMember && orderId ? await this.ordersApi.getOrder(orderId) : undefined,
          true,
        );
      } catch (e) {
        if (e instanceof PurchaseLimitExceededError) {
          this.setProps({ message: MessageCode.PURCHASE_LIMIT_ERROR });
        } else {
          this.setProps({ message: MessageCode.UNKNOWN_ERROR });
        }
      }
    }
  }

  async update(
    selectedPlan: PublicPlan,
    integrationData: IntegrationData,
    order?: V1OrdersOrder,
    showUpgradeModal = false,
  ): Promise<void> {
    this.flowAPI.fedopsLogger.interactionStarted(PackagePickerInteractions.CheckoutPageLoaded);
    this.analytics.viewContent(selectedPlan);
    this.setProps({
      order,
      selectedPlan,
      logout: this.logout,
      loginOnCheckout: () => this.promptLogin('login', selectedPlan, integrationData),
      signupOnCheckout: () => this.promptLogin('signup', selectedPlan, integrationData),
      navigateToStatus: this.navigateToStatus,
      benefits: this.wixCodeApi.site.getAppToken?.(BOOKINGS_APP_DEF_ID)
        ? await this.benefitsApi.listPlanBookingsBenefits(selectedPlan.id ?? '')
        : [],
      trackInitiateCheckout: () => this.analytics.initiateCheckout(selectedPlan),
      trackSelectPayment: (id: string) => this.analytics.selectPayment(id),
      demoBuyNowClicked: () => this.navigateToDemoStatus(selectedPlan, integrationData),
      biCheckoutStage: (stage) => this.bi.checkoutStage(stage, selectedPlan.id),
      biPlanPurchased: (result: PaymentCompleteResultPublic, purchaceOrder: V1OrdersOrder) =>
        this.bi.planPurchased(result, selectedPlan, purchaceOrder),
    });
    const { currentUser } = this.wixCodeApi.user;
    if (currentUser.loggedIn) {
      this.initializeForUser(currentUser, selectedPlan, integrationData, order, showUpgradeModal);
    } else {
      this.setProps({
        detailedPrice: await this.ordersApi.getDetailedPrice(selectedPlan.id!),
      });
    }
  }

  promptLogin = async (mode: 'signup' | 'login', plan: PublicPlan, integrationData: IntegrationData) => {
    const user = await this.wixCodeApi.user.promptLogin({ mode, modal: true });
    return this.initializeForUser(user, plan, integrationData, undefined, true);
  };

  initializeForUser = async (
    user: IUser,
    selectedPlan: PublicPlan,
    integrationData: IntegrationData,
    order?: V1OrdersOrder,
    showUpgradeModal = false,
  ) => {
    this.updateUserInfo(user);
    if (!isFree(selectedPlan) && (await this.premiumApi.shouldUpgrade())) {
      const isAdmin = user.role === 'Admin';
      return this.setProps({
        message: isAdmin ? MessageCode.CHECKOUT_DEMO : undefined,
        showUpgradeModal: isAdmin && showUpgradeModal,
        shouldUpgrade: true,
        detailedPrice: await this.ordersApi.getDetailedPrice(selectedPlan.id!),
      });
    } else if (!order) {
      try {
        order = await this.ordersApi.createOrder(selectedPlan, user.id);
        if (order) {
          this.setProps({ order });
          if (isFree(selectedPlan)) {
            this.router.gotoStatus(selectedPlan, order, integrationData);
          }
        }
      } catch (e) {
        this.setProps({ message: errorToMessage(e) });
      }
    }
  };

  updateUserInfo = async (user: IUser) => {
    const userData = getUserData(user);
    try {
      const userEmail = await user.getEmail();
      this.setProps({ user: userData, userEmail });
    } catch (e) {
      this.flowAPI.reportError(new Error('Failed to get user email: ' + e));
      this.setProps({ user: userData });
    }
  };

  navigateToStatus = (
    result: PaymentCompleteResultPublic,
    plan: PublicPlan,
    order: V1OrdersOrder,
    integrationData: IntegrationData,
  ) => {
    const resultReader = new PaymentResultReader(result);
    this.router.gotoStatus(plan, order, integrationData, {
      ok: resultReader.isOk(),
      error: resultReader.getTranslatedError(),
    });
  };

  navigateToDemoStatus = (plan: PublicPlan, integrationData: IntegrationData) =>
    this.router.gotoStatus(plan, {}, integrationData, { ownerDemo: true });

  logout = () => {
    this.wixCodeApi.user.logout();
  };
}
