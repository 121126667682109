import { V1OrdersOrder } from '@wix/ambassador-membership-api/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { IWixWindow } from '@wix/native-components-infra/dist/src/types/types';
import { getPlanCategory, getOrigin } from '../utils/analytics';
import { getPrice } from '../utils/plan';

export class Analytics {
  constructor(protected window: IWixWindow, protected referralInfo?: string) {}

  setReferralInfo(referralInfo?: string) {
    this.referralInfo = referralInfo;
  }

  addProductImpression(plans: PublicPlan[]) {
    this.window.trackEvent('AddProductImpression', {
      origin: getOrigin(this.referralInfo),
      contents: plans.map((plan) => ({
        id: plan.id,
        name: plan.details?.name,
        category: getPlanCategory(plan),
        price: getPrice(plan),
        currency: plan.paymentOptions?.price?.currency,
        quantity: 1,
      })),
    });
  }

  clickProduct(plan: PublicPlan) {
    this.window.trackEvent('ClickProduct', {
      origin: getOrigin(this.referralInfo),
      name: plan.details?.name,
      category: getPlanCategory(plan),
      price: getPrice(plan),
      currency: plan.paymentOptions?.price?.currency,
    });
  }

  purchase(plan: PublicPlan, order: V1OrdersOrder, confirmed: 'yes' | 'no' | 'unknown' = 'unknown') {
    this.window.trackEvent('Purchase', {
      origin: getOrigin(this.referralInfo),
      id: order.id,
      // @ts-ignore
      orderId: order.id,
      confirmed,
      // @ts-ignore
      revenue: getPrice(plan),
      currency: plan.paymentOptions?.price?.currency,
      contents: [
        {
          id: plan.id,
          name: plan.details?.name,
          category: getPlanCategory(plan),
          price: getPrice(plan),
          quantity: 1,
        },
      ],
    });
  }

  viewContent(plan: PublicPlan) {
    this.window.trackEvent('ViewContent', {
      origin: getOrigin(this.referralInfo),
      name: plan.details?.name,
      category: getPlanCategory(plan),
      price: getPrice(plan),
      currency: plan.paymentOptions?.price?.currency,
    });
  }

  selectPayment(payment: string) {
    this.window.trackEvent('CustomEvent', {
      // @ts-ignore
      event: 'SelectPayment',
      origin: getOrigin(this.referralInfo),
      payment_method: payment,
    });
  }

  initiateCheckout(plan: PublicPlan) {
    this.window.trackEvent('InitiateCheckout', {
      origin: getOrigin(this.referralInfo),
      contents: [
        {
          id: plan.id,
          name: plan.details?.name,
          category: getPlanCategory(plan),
          price: getPrice(plan),
          currency: plan.paymentOptions?.price?.currency,
          quantity: 1,
        },
      ],
    });
  }
}
