import { Benefit, PricingPlanBenefitsServer } from '@wix/ambassador-pricing-plan-benefits-server/http';
import { BOOKINGS_APP_DEF_ID } from '../constants';
import { isTruthy } from '../utils/isTruthy';
import { ApiHeaders } from './headers';

export type PricingPlanBenefitsApi = ReturnType<typeof PricingPlanBenefitsServer>;
export type BenefitsManagementService = ReturnType<PricingPlanBenefitsApi['BenefitManagement']>;

export class BenefitsApi {
  constructor(protected headers: ApiHeaders, protected benefits: BenefitsManagementService) {}

  public async listPlanBookingsBenefits(planId: string): Promise<Benefit[]> {
    const { benefitsWithPlanInfo = [] } = await this.benefits(this.headers).list({
      planIds: [planId],
      appDefIds: [BOOKINGS_APP_DEF_ID],
    });
    return benefitsWithPlanInfo
      .filter(({ benefit }) => benefit && benefit.resourceIds?.length)
      .map((info) => info.benefit)
      .filter(isTruthy);
  }
}

export function createBenefitsApi(headers: ApiHeaders, baseUrl: string = '') {
  return new BenefitsApi(
    headers,
    PricingPlanBenefitsServer(baseUrl + '/_api/pricing-plan-benefits').BenefitManagement(),
  );
}
